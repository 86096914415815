@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
/*$color2: #5abd8d;*/
/*$color3: #fff200;*/
/*$color4: #f7941d;*/
/*$gray-base:              #000;*/
/*$brand-primary:         $color1;*/
/*$text-color:            $gray-dark;*/
/*$link-color:            $brand-primary;*/
/*$link-hover-color:      darken($link-color, 15%);*/
/*$link-hover-decoration: underline;*/
/*$font-family-base:        $font-family-sans-serif;*/
/*$font-size-base:          14px;*/
/*$headings-font-family:    $font1;*/
/*$headings-font-weight:    700;*/
/*$headings-line-height:    1.1;*/
/*$headings-color:          inherit;*/
/*$border-radius-base:        2px;*/
/*$border-radius-large:       2px;*/
/*$border-radius-small:       2px;*/
/*$btn-font-weight:                normal;*/
/*$btn-default-color:              $brand-primary;*/
/*$btn-default-bg:                 #fff;*/
/*$btn-default-border:             $brand-primary;*/
/*$btn-primary-color:              #fff;*/
/*$btn-primary-bg:                 $brand-primary;*/
/*$btn-primary-border:             $brand-primary;*/
/*$input-bg:                       #fff;*/
/*$input-bg-disabled:              $gray-lighter;*/
/*$input-border:                   #ccc;*/
/*$input-border-radius:            $border-radius-base;*/
/*$input-border-radius-large:      $border-radius-large;*/
/*$input-border-radius-small:      $border-radius-small;*/
/*$input-border-focus:             #66afe9;*/
/*$input-color-placeholder:        #999;*/
/* Add your own css here */
/*Place styles above this line*/
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    margin: 5px; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: white;
      display: block;
      -webkit-backface-visibility: visible;
      -webkit-transition: opacity 200ms ease;
      -moz-transition: opacity 200ms ease;
      -ms-transition: opacity 200ms ease;
      -o-transition: opacity 200ms ease;
      transition: opacity 200ms ease;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #000; }
